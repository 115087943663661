import React from "react";

const Techstack = ({stack}) => {
    return (
           <div class="tags is-centered">
{
    stack.map((element) => (
        //  <button class="button is-black is-small is-responsive is-rounded">{element}</button>
         <span class="tag is-rounded is-medium is-link is-light is-size-6-touch">{element}</span>

    ))
    }
</div>
    )
}

export default Techstack;
import React from 'react';
import Photo from './static/Headshot.jpeg'
const Homepage = () => {
return (


<div class="container is-fluid">
   
    <div class="columns is-vcentered is-centered">

    

    <div class="column has-text-centered">
        <div class="block">
    <h1 class="title is-1-tablet">Hello, I'm Justin 👋</h1>
        </div>

        <div class="block">

      
      <p class="is-size-4-tablet">I'm a Computing Science graduate with a minor in Economics.  <br/> 
      
      I'm interested in software engineering, databases, computer networks, operating systems, and web development. <br/>       
      I was a fellow for the Summer 2022 Production Engineering Fellowship with MLH Fellowship and Meta.</p>
        </div>
  <div class="block">

    <div class="buttons is-centered">

  

       <a href="https://github.com/jmonteza" target="_blank" rel="noopener noreferrer" class="button is-large is-responsive">
    <span class="icon is-medium">
      <i class="fab fa-github"></i>
    </span>
    <span>GitHub</span>
  </a>

    <a href="https://www.linkedin.com/in/justinmonteza" target="_blank"  rel="noopener noreferrer" class="button is-large is-responsive">
    <span class="icon is-medium">
      <i class="fab fa-linkedin"></i>
    </span>
    <span>LinkedIn</span>
  </a>

      <a href="mailto: hello@justinmonteza.com" target="_blank" rel="noopener noreferrer" class="button is-large is-responsive">
    <span class="icon is-medium">
      <i class="fas fa-at"></i>
    </span>
    <span>Email</span>
  </a>

  </div>

  </div>


      </div>

     
     <div class="column">
    
       <figure class="image is-1x1">
  <img src={Photo}/>
</figure> 

      </div>

     </div>
 </div>

)
}

export default Homepage;
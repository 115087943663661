import './App.css';
import React from 'react';
import NavigationBar from './Navigation';
import Homepage from './Home';
import Projects from './Projects';
import Footer from './Footer';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
function App() {
  return (
    <>
    <BrowserRouter>
    <NavigationBar/>
   {/* <Homepage/> */}
   <Routes>
     <Route path="/" element={<Homepage/>}/>
     <Route path="/projects" element={<Projects/>}/>
   </Routes>
   <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;

import React from "react"
const Footer = () => {
    return (
        <footer class="footer has-background-white">
  <div class="content has-text-centered">
    <p>
        ©Justin Monteza
    </p>
  </div>
</footer>
    )
}

export default Footer;
import React from 'react';
import { Link } from 'react-router-dom';

const NavigationBar = () => {

    const [isActive, setIsActive] = React.useState(false);

    const toggleBurger = () => {
        setIsActive(!isActive);
    }

    return (
        <nav class="navbar is-transparent" role="navigation" aria-label="main navigation" >
  <div class="navbar-brand">

       <Link to="/" className="navbar-item ">
        <h1 class="is-uppercase has-text-weight-bold">Justin Monteza</h1>
       </Link>
   

    <a role="button" onClick={() => toggleBurger()} class={`navbar-burger ${isActive ? "is-active" : ""}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
      <span aria-hidden="true"></span>
    </a>
  </div>

  <div id="navbarBasicExample" class={`navbar-menu ${isActive ? "is-active": ""}`}>
    <div class="navbar-start">
      {/* <a class="navbar-item">
        Home
      </a> */}
    <Link onClick={() => toggleBurger()} to="/" className="navbar-item has-text-weight-bold">Home</Link>
     {/* < <a class="navbar-item">
        Projects
      </a>> */}
      <Link onClick={() => toggleBurger()} to="/projects" className="navbar-item has-text-weight-bold">Projects</Link>

      {/* <div class="navbar-item has-dropdown is-hoverable">
        <a class="navbar-link">
          More
        </a>

        <div class="navbar-dropdown">
          <a class="navbar-item">
            About
          </a>
          <a class="navbar-item">
            Jobs
          </a>
          <a class="navbar-item">
            Contact
          </a>
          <hr class="navbar-divider"/>
          <a class="navbar-item">
            Report an issue
          </a>
        </div>
      </div> */}



    </div>

    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          {/* <a class="button is-primary">
            <strong>Sign up</strong>
          </a> */}
          <a  target="_blank" rel="noopener noreferrer" href="https://blog.justinmonteza.com" class="button is-dark is-light has-text-weight-bold">
            Blog
          </a>
        </div>
      </div>
    </div>


  </div>
</nav>
    )
}

export default NavigationBar;
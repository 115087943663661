import React from "react";
import Techstack from "./Techstack";

const Project = (props) => {

    const {name, github, link, image, stack, description} = props.element;
   
    return (
        <div class="column" id="column-custom">


<div class="card">


  <header class="card-header">
    <p class="is-size-3-tablet card-header-title">
      {name}
    </p>
    {
      github ? 

         <a target="_blank" rel="noopener noreferrer" href={github} class="card-header-icon" aria-label="more options">
      <span class="is-size-3-tablet icon">
        <i class="fab fa-github" aria-hidden="true"></i>
      </span>
      </a>
      :
      null

    }

    {
      link ? 
    <a target="_blank" rel="noopener noreferrer" href={link} class="card-header-icon" aria-label="more options">
      <span class="is-size-3-tablet icon">
        <i class="fas fa-link" aria-hidden="true"></i>
      </span>
    </a> :
    null

}

  </header>

  <div class="card-image">
    <figure class="image is-3by4r">
      <img src={image} alt="Placeholder image"/>
    </figure>
  </div>


  <div class="card-content has-text-centered">
    {/* <div class="media">
      <div class="media-left">
        <figure class="image is-128x128">
          <img src="https://bulma.io/images/placeholders/96x96.png" alt="Placeholder image"/>
        </figure>
      </div>
      <div class="media-content">
        <p class="title is-4">John Smith</p>
        <p class="subtitle is-6">@johnsmith</p>
      </div>
    </div> */}

    {/* <div class="content">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
      Phasellus nec iaculis mauris. <a>@bulmaio</a>.
      <a href="#">#css</a> <a href="#">#responsive</a>
      <br/>
      <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
    </div> */}
    {/* <p class="title is-centered">Slide Automator</p>  */}

    <Techstack stack={stack}/>

    <p class="is-size-6-touch is-size-4-tablet">{description}</p>

  </div>

      {/* <footer class="card-footer"> */}
    
    {/* <a href="#" class="card-footer-item">Visit</a> */}

       {/* <a href="#" class="card-footer-item">Source Code</a> */}

  {/* </footer> */}

</div>


</div>

    )
}

export default Project;
import React from 'react';
import Project from './Project';
import data from './Projects.json'
import {chunk} from 'lodash';

const first = [0, 3, 6, 9, 12]

const last = [2, 5, 8, 11, 14]

const Projects = () => {

    const parents = chunk(data, 3);

    return (
        <div class="container is-fluid has-text-centered">
        
        <h1 class="is-size-4-touch is-size-1">Projects</h1>


{
parents.map((children) => (
    <div class="columns">
    {
    children.map((child) => (
        <Project element={child}/>
    ))
    }
    </div>
))

}
</div>


    )
}

export default Projects;